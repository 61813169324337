import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '@tokens/api-endpoints.token';
import { Observable } from 'rxjs';
import { RegistrationRequest, RegistrationResponse } from '@services/registration/registration.types';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  protected http = inject(HttpClient);
  protected endpoints = inject(API_ENDPOINTS);

  checkInvitationToken(token: string): Observable<Record<string, never>> {
    return this.http.get<Record<string, never>>(this.endpoints.check_invitation_token, {
      params: {
        invitation_token: token,
      },
    });
  }

  register(data: RegistrationRequest): Observable<RegistrationResponse> {
    return this.http.put<RegistrationResponse>(this.endpoints.invitation, data);
  }
}
